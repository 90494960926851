<template>
  <div>
    <NavbarGuest />
    <v-app-bar dark color="#19647E">
      <v-icon large class="mr-3">text_snippet</v-icon>
      <v-toolbar-title>Set Password</v-toolbar-title>
    </v-app-bar>
    <v-card>
      <v-container>
        <v-form ref="form">
          <v-text-field
            v-model="profile.password"
            label="New Password"
            v-validate="'required|min:8'"
            required
            data-vv-name="New Password"
            :error-messages="errors.collect('New Password')"
            ref="newPassword"
            :type="showNewPassword ? 'text' : 'password'"
            :append-icon="showNewPassword ? 'visibility' : 'visibility_off'"
            @click:append="showNewPassword = !showNewPassword"
          ></v-text-field>

          <v-text-field
            v-model="profile.confirmNewPassword"
            label="Confirm New Password"
            v-validate="'required|min:8|confirmed:newPassword'"
            required
            data-vv-name="Confirm New Password"
            :error-messages="errors.collect('Confirm New Password')"
            ref="confirmPassword"
            :type="showConfirmPassword ? 'text' : 'password'"
            :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
            @click:append="showConfirmPassword = !showConfirmPassword"
          ></v-text-field>
          <v-btn dark color="#19647E" @click.prevent="setPassword()"
            >Update Password</v-btn
          >
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>


<script>
import NavbarGuest from '../components/NavbarGuest.vue'
import { DataService as CensusDataService } from "./../services/CensusDataService";
const censusDataService = new CensusDataService();

const Hashids = require("hashids/cjs");
export default {
    components: {
    NavbarGuest
  },
  data() {
    return {
      showNewPassword: false,
      showConfirmPassword: false,
      profile: {},
    };
  },

  mounted() {
    const hashids = new Hashids("IZC", 32);
    let decodedTimestamp = hashids.decode(this.$route.query.time);
    if (decodedTimestamp.length == 0 || decodedTimestamp[0] == undefined || decodedTimestamp[0] < Date.now()) {
      this.redirectToLinkNotFound();
    }
    this.validateLink();
  },

  methods: {
    async setPassword() {
      let data = {
        userId: this.$route.query.userId,
        umId: this.$route.query.umId,
        type: this.$route.query.type,
        password: this.profile.password,
        code: this.$route.query.code,
      };
      let v = await this.$validator.validateAll();
      if (v) {
        this.$setLoader();
        censusDataService.setPassword(data).then(() => {
          this.$clearLoader();
          this.$router.push({ name: "Dashboard" });   
        });
      }
    },

    validateLink() {
      let data = {
        code: this.$route.query.code,
        userId: this.$route.query.userId,
      };
      censusDataService.validatePasswordLink(data).then((res) => {
        if (res.data.isValid.length == 0) {
          this.redirectToLinkNotFound();
        }
      });
    },

    redirectToLinkNotFound() {  
      this.$router.push({ path: "/410" });
    },
  },
};
</script>